import { useEffect, useState, useContext, useRef } from "react";
import { userContext } from "../../Context";
import { MessageBox } from "react-chat-elements";
import { MessageList } from "react-chat-elements";
import { Button } from "react-chat-elements";
import { Provider, RateButton } from "@lyket/react";
import Speech from "speak-tts";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { getCurrentTimestamp } from "../../helpers/Utils";
import './home.css'
import axios from "../../helpers/axios";
import logo from "../../assets/char_jini_nbg.png";
import submit from "../../assets/send.png";
import mute from "../../assets/mute.png";
import keyboard from "../../assets/keyboard.png";
import microphone from "../../assets/microphone.png";
import mic from "../../assets/mic.png";
import speaking from "../../assets/speaking.png";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import prompts from './prompts.json'
import { countno } from "./count"

import itemImages from "./images.json"

// import { Configuration, OpenAIApi } from "openai";
import Recorder from 'recorder-js';
import Fuse from "fuse.js";
const mimeType = "audio/webm";


let t = [
  {
    alt: "Jini",
    position: "left",
    type: "text",
    text: "Hi! I’m Dr. Jini, and I’m here to help you feel healthier—right now! Tap the mic and say something like ‘My knee hurts,’ ‘I’m feeling lonely,’ or ‘I’m sleep-deprived but need to work.’ Let’s tackle it together!",
  },
];




const getCartItemImage = (item) =>{
  const fuse = new Fuse(Array.from(itemImages), {keys: ['Name', 'hindi_name']})
  return fuse.search(item.name)[0].item.image_url
}

const default_id = 1;
const item = prompts.find(item => item.id === default_id);
const copyOfitem = item.description.slice();
let messages = [];
if (item) messages = copyOfitem;
else console.log(`Item with ID ${default_id} not found.`);


let i = 0;
const apiEndpoint = "https://iunsm6j15c.execute-api.ap-south-1.amazonaws.com/dev//sayjini2processmessages";
// const apiEndpoint = "https://erafaq3v92.execute-api.ap-south-1.amazonaws.com/prod/process-messages";
const Home = ({myObj1, getMyOBj}) => {
  myObj1 = getMyOBj();
  const [count, setCount] = useState(0);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [permission, setPermission] = useState(false);
  const [userDetailsformData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contact: '',
    address: '',
    latitude: null,
    longitude: null,
    order_timestamps: null,
    order_kart: [],
    total_price:0
  });
  
const mediaRecorder = useRef(null);
const [recordingStatus, setRecordingStatus] = useState("inactive");
const [stream, setStream] = useState(null);
const [audioChunks, setAudioChunks] = useState([]);
const [audio, setAudio] = useState(null);
const [transcriptPro,setTranscriptPro] = useState(0);
const [jsonMessage,setJsonMessage] = useState(null);
  useEffect(() => {
    const storedCount = localStorage.getItem("count");
  
    if (storedCount === null) {
      localStorage.setItem("count", 0);
      setCount(0);
      countno.style.display = 'none';
    } else {
      const parsedCount = parseInt(storedCount, 10);
      setCount(parsedCount);
  
      if (parsedCount === 50 || (parsedCount > 50 && (parsedCount - 50) % 4 === 0)) {
        countno.style.display = 'flex';
      } else {
        countno.style.display = 'none';
      }
    }
  }, []);
  

  useEffect(() => {
    if(myObj1){
      console.log('myObj1 in home');
    console.log(JSON.stringify(myObj1));
      setFormData({
        firstName: myObj1.firstName,
        lastName: myObj1.lastName,
        contact: myObj1.contact,
        address: myObj1.address,
        latitude: myObj1.latitude,
        longitude: myObj1.longitude,
      })
    }
    
  }, [myObj1]);
  document.body.style.height = "calc(100vh - 60px)";
  const { currentUser } = useContext(userContext);
  const messageListRef = useRef(null);
  
  // const configuration = new Configuration({ apiKey: sec_key });
  // const openai = new OpenAIApi(configuration);
  
  const [tt, settt] = useState([]);
  const [isprocessing, setIsprocessing] = useState(false);
  const [arr,setArr] = useState([]);
  
  const handleUpdateMessagesrwo = (p) => {
    const textt = p.text;
    const pot = { position: "right", type: "text", text: textt };
  
    t.push(pot);
  
    if (!textt.startsWith("id@:")) {
      setIsprocessing(true);
    }
  
    settt((prevState) => [...prevState, "val"]);
    resetTranscript();
  };
  
  const handleUpdateMessages = (p, inputTimestamp) => {
    processMessage(p.text, inputTimestamp);
  };
  const setTheCartValues = ()=> {

  };
  
  useEffect(() => {
    const element = document.getElementById("my-element");
    const per = getPer(window.innerHeight);
  
    if (element) {
      const vh = window.innerHeight * per;
      element.style.height = `${vh}px`;
    }
  }, []);
  
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [tt]);
  
  const getPer = (height) => {
    const breakpoints = [
      { height: 1300, per: 0.8 },
      { height: 1000, per: 0.73 },
      { height: 900, per: 0.7 },
      { height: 750, per: 0.67 },
      { height: 700, per: 0.645 },
    ];
  
    for (const breakpoint of breakpoints) {
      if (height >= breakpoint.height) {
        return breakpoint.per;
      }
    }
    return 0.59;
  };
  
  const handleHello = (message, pp, inputTimestamp,arra) => {
    const p = {
      alt: "Jini",
      position: "left",
      type: "text",
      text: <div>{arra.length>0 ? <div><p>Ok, Here is your final list:</p> 
        <table>
  <thead>
    
  </thead>
  <tbody>
    {arra.map(ar => (
      <tr className="cart-item" key={ar.name}>
        <td><img className="cart-item-image" onError={(e)=>{e.target.src = "https://cdn-icons-png.flaticon.com/512/135/135763.png"}} src={getCartItemImage(ar)} /></td>
        <td className="cart-item-data">{ar.name}<br/> {ar.quantity} {ar.unit}</td>
        <td>₹ {ar.price}</td>
      </tr>
    ))}
    {/* Total Row */}
    <tr className="cart-item-headings"><td><b>Bill Details:</b></td></tr>
    <tr className="cart-price">
      <td className="cart-price-item">MRP</td>
      <td></td>
      <td>
      ₹{arra.reduce((total, ar) => total + parseInt(ar.price), 0)} 
      </td>
    </tr>
    <tr className="cart-price">
      <td className="cart-price-item">Auto Discount</td>
      <td></td>
      <td className="text-green">
        -₹{(0.2*(arra.reduce((total, ar) => total + parseInt(ar.price), 0))).toFixed()}
      </td>
    </tr>
    <tr className="cart-price">
      <td className="cart-price-item">Total</td>
      <td></td>
      <td className="text-green">
      ₹{((arra.reduce((total, ar) => total + parseInt(ar.price), 0))- 0.2*(arra.reduce((total, ar) => total + parseInt(ar.price), 0))).toFixed()}
      </td>
    </tr>
  </tbody>
</table><button className="cart-button" onClick={async()=>{
  // let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // // console.log(`userDetails: ${JSON.stringify(userDetails)}`)
  // const data ={
  //   method: 'post',
  //   url: 'https://t1yprcpax8.execute-api.ap-south-1.amazonaws.com/dev//orderkart',
  //   data: {
  //     firstName: userDetails.firstName,
  //     lastName: userDetails.lastName,
  //     contact: userDetails.contact,
  //     address: userDetails.address,
  //     latitude: userDetails.latitude,
  //     longitude: userDetails.longitude,
  //     order_timestamps: null,
  //     order_kart: arra,
  //     user: userDetails.user
  //   }
  // }
  // // console.log('aryan in order');
  // // console.log(userDetails);
  // // console.log(JSON.stringify(data));

  // const response = await axios(data);
  // setIsDialogOpen(false);
  // setShowPopup(true);
  // console.log('mail sent')
  // console.log(response);
  t.push({position: "left",
  type: "text", 
  text: 'Your order is confirmed & will be delivered by Gupta Fruit Shop in 40 mins'})
  speakMessage('Your order is confirmed & will be delivered by Gupta Fruit Shop in 40 mins')
  setTimeout(() => {
    setShowPopup(true)
  }, 3000);
  setArr([]);
  settt([]);
}} style = {{padding: "5px 10px", marginLeft: "20px"}}>Confirm Order</button></div>:<p>{message}</p>}</div>
    };
    console.log(arr);
    console.log('inside handle hello');
    t.push(p);
    settt((prevState) => [...prevState, "val"]);
    postMessage(message, inputTimestamp);
    resetTranscript();
  };
  
  const postMessage = async (message, inputTimestamp) => {
    const count = parseInt(localStorage.getItem("count"), 10) + 1;
    localStorage.setItem("count", count);
    setCount(count);
  
    countno.style.display = count === 750 || (count > 750 && (count - 750) % 4 === 0) ? "flex" : "none";
  
    try {
      const data = {
        email: currentUser ? currentUser.attributes.email : "none",
        prompt: lastPrompt,
        response: message,
        prompt_timestamps: inputTimestamp,
        response_timestamps: getCurrentTimestamp(),
        status: "200",
      };
  
      const res = await axios.post("/jinipayload", data);
  
      if (res) {
        console.log(res);
      } else {
        console.log("postMessage Failed !");
      }
    } catch (err) {
      console.log(err);
    }
  };
  
// OpenAI API
  const processMessage = (message, inputTimestamp) => {
    async function runCompletion() {
      const userMessage = { role: "user", content: message };
      messages.push(userMessage);

      // const completion = await openai.createChatCompletion({
      //   model: "gpt-3.5-turbo",
      //   messages: messages,
      // });
      console.log('in messages in open Ai api');
      console.log(JSON.stringify(messages));
      fetch(apiEndpoint, {
        method: 'POST',
        mode: 'cors', 
        body: JSON.stringify( {'messages':messages}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        // console.log('open ai api');
        // Move to backend        
        // if (completion.data.usage.total_tokens > 500) {
        //   messages.splice(1, 2);
        // }
        // data = `<h1>${data}</h1>`;\
        console.log('in response');
        console.log(data);
        data=data.body;
        let regex = /\{\\\"([^\\\"]+)\\\", (\d+), \\\"([^\\\"]+)\\\", (\d+)\}/g;
        const extractedObjects = [];
        let inputString = data;
        let match;
        // (match = regex.exec(inputString));
        // console.log(`match ${match}`);
        while ((match = regex.exec(inputString)) !== null) {
          const name = match[1];
          const quantity = parseInt(match[2]);
          const unit = match[3];
          const price = parseInt(match[4]);
          extractedObjects.push({ name, quantity, unit, price });
        }
        if((match = regex.exec(inputString)) === null){
          regex =/\{"([^"]+)",\s*(\d+),\s*"([^"]+)",\s*(\d+)\}/g;
        // const extractedObjects = [];
        inputString = data;
        // match;
        // (match = regex.exec(inputString));
        // console.log(`match ${match}`);
        while ((match = regex.exec(inputString)) !== null) {
          const [, name, quantity, unit, price] = match;
        // const extractedObjects = [];
        extractedObjects.push({
            name,
            quantity: parseInt(quantity),
            unit,
            price: parseInt(price)
          });
        }
        }
        
        console.log('aryan extracted object');
        // arr=(extractedObjects);
        setArr(extractedObjects);
        console.log(extractedObjects);
        console.log('arr');
        let total = 0
        extractedObjects.map(item=>total = total+item.price)
        
        setIsprocessing(false);
        handleHello(data, message, inputTimestamp,extractedObjects);
        const parsed = data.substring(data.indexOf('['), data.indexOf(']') + 1);
        console.log('speech log: ', parsed )
        if(parsed !== '') {
          speakMessage('Here is your list ' + parsed + 'Bill Total is ' + (total - total*0.2).toFixed() );
          speakMessage('Do you want anything else?')          
        }
        else{
          speakMessage(data) 
        }

      })
      .catch(error => {
        console.log(error);
        // const errMsg = error.response ? "Bad response" : "Error code 92 gyar";
        const errMsg = "Apologies, I didn't get that. Please say again.";
        setIsprocessing(false);
        handleHello(errMsg, message, inputTimestamp);
        speakMessage({role: "user", content: errMsg });
      });
    }
    runCompletion();
  };

  const speech = new Speech();
  const isEdge = navigator.userAgent.indexOf("Edg") !== -1;
  const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
  // const isIphone = navigator.userAgent.indexOf("Chrome") !== -1;
  const availvoice = "Google हिन्दी";
  const lang = isChrome || isEdge ? "hi-IN" : "en-IN";

  speech.init({
    volume: 1,
    lang: lang,
    rate: 1,
    pitch: 1,
    splitSentences: true,
    listeners: {
      onvoiceschanged: () => {
        const synth = window.speechSynthesis;
        const availableVoices = synth.getVoices();
        // console.log(`inside speech ${navigator.userAgent} `);
        // isChrome = true;
        if (isChrome && !isEdge) {
          const googleVoice = availableVoices.find(voice => voice.name === availvoice);
          speech.setVoice(googleVoice ? availvoice : "Microsoft Heera - English (India)");
        } else {
          speech.setVoice(isEdge ? "Microsoft Swara Online (Natural) - Hindi (India)" : "Microsoft Heera - English (India)");
        }
      },
    },
  });

  const speakMessage = (message) => {
    messages.push({ role: "assistant", content: message });

    speech
      .speak({
        text: message,
        listeners: {
          onstart: () => setIsspeaking(true),
          onend: () => setIsspeaking(false),
        },
        })
      .then(() => { console.log("Success speaking !");})
      .catch((e) => {console.error("An error occurred :", e);});
  };
  
  let transcriptProcessed = 0;  
  const handleEnteredText = (text) => {
  const userMessage = {
    position: "right",
    type: "text",
    title: "user",
    text: text,
  };

  const inputTimestamp = getCurrentTimestamp();
  if (text.startsWith("id@:")) {
  const id = parseInt(text.slice(4));

  if (!isNaN(id)) {
    handleUpdateMessagesrwo(userMessage);
  
    const item = prompts.find((item) => item.id === id);
  
    if (item) {
      messages = [];
      let temp = item.description.slice();
      t = [      {       alt: "Jini",        position: "left",        type: "text",               text: item.input_text,      },    ];
      messages = temp;
    } else {
      let notFoundMessage = {
        alt: "Jini",
        position: "left",
        type: "text",
        text: "ID does not exist",
      };
      t.push(notFoundMessage);
      settt([...tt, "val"]);
      postMessage("ID does not exist", inputTimestamp);
      resetTranscript();
    }
    settt([]);
  }
  
} else {
  handleUpdateMessagesrwo(userMessage);
  // console.log('inside handleUpdateMessage');
  // console.log(userMessage);
  handleUpdateMessages(userMessage, inputTimestamp);
  }
  };
  
  const {
  transcript,
  listening,
  resetTranscript,
  finalTranscript,
  browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  
  // if (listening) {
  // transcriptProcessed = 0;
  // }
  console.log(`transcript prcessed ${transcript}`);
  console.log(transcriptPro);
  console.log(listening);
  if (transcriptPro === 0 && transcript !== "" && !listening) {
  setTranscriptPro(10);
  transcriptProcessed = 10;
  console.log('abcd');
  console.log(transcript);
  handleEnteredText(transcript);
  }
  
  const cancelaudio = () => {
  speech.cancel();
  setIsspeaking(false);
  };
  const showCart = () => {
    let cart =[{
      id:1,
      name: "Potato",
      quantity: "1kg",
      price: "1 Rs/gm",
      "Total Amount": "1000 Rs"
    }]
  }

  const [micExpanded, setMicExpanded] = useState(true);
  const [inputExpanded, setInputExpanded] = useState(false);

  function toggleMicInput() {
    setMicExpanded(!micExpanded);
    setInputExpanded(!inputExpanded);
  }

  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState("");
  const [lastPrompt, setLastPrompt] = useState("");
  
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    inputRef.current.value = inputValue;
    setValue(inputValue);
    setLastPrompt(inputValue);
  };  

  const handleInputBlur = () => {
    if (value.trim() === "") {
      setExpanded(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleEnteredText(value);
    setValue("");
  };

  const [isListening, setIsListening] = useState(false);
  const [isspeaking, setIsspeaking] = useState(false);

  
  
  const handleClick = () => {
    if (isspeaking) {
      setIsspeaking(false);
      setIsListening(false);
      // set
      SpeechRecognition.stopListening();
      speech.cancel();
    } else if (isListening) {
      setIsListening(false);
      SpeechRecognition.stopListening();
      
    } else {
      setIsListening(true);
      SpeechRecognition.startListening({
        continuous: false,
        autoStopTimeout: 1000,
      });
    }
  };
  let timeout;
  const handleTouchEnd = async () => {
    
      console.log('aryan 3');
      // setTranscriptPro(0);
      setIsspeaking(false);
      SpeechRecognition.stopListening();
      speech.cancel();
      
      setIsListening(false);
      let id=0;
      while(id<=100000)
      id++;

      setTranscriptPro(0);
      
      console.log('aryan 4');
      
      
      
      console.log('aryan 5');
    // handleClick(); // Call handleClick on touch end
  };

  const handleTouchStart = () => {
    setIsListening(true);
    console.log('aryan 1');
    SpeechRecognition.startListening({
      continuous: false,
    });
    console.log('aryan 2');
    // timeout = setTimeout(() => {
    //   SpeechRecognition.stopListening();// Call handleClick when hold duration is reached
    // }, 100000000); // Adjust the time as needed for the hold duration
  };
  // const stopRecording = async () => {
  //   // if (recorder) {
  //     SpeechRecognition.stopListening();
  //     mediaRecorder.current.stop();
  //     var superBuffer = new Blob(audioChunks);
  //     // const audioBlob = await recorder.exportWAVBlob();
  //     // Now you have the audio Blob that you can use/save as needed
  //     console.log('aryan ');
  //     console.log(superBuffer);
  //     setIsRecording(false);
  //   // }
  // };
  const handleMouseUp = async () => {
    console.log('aryan mouse up')
      SpeechRecognition.stopListening();
      setIsspeaking(false);
      
      speech.cancel();

      setIsListening(false);
      console.log('aryan mouse up')
      console.log(transcript);
      console.log(isListening);
      console.log(isspeaking);
      setTranscriptPro(0);

    // clearTimeout(timeout);
    // SpeechRecognition.stopListening()
    // await stopRecording();
    // setTranscriptPro(0);
    // console.log(`called mov Up ${transcriptPro}`)
    // handleClick(); // Call handleClick on mouse up
  };
  const [showPopup, setShowPopup] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleRatingChange = (event) => {
    setRating(Number(event.target.value));
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };
  const submitFeedback = () => {
    // Here you can send the 'rating' and 'feedback' to the backend
    // using an API call or any other method you prefer.

    // After submitting feedback, close the popup
    closePopup();
  };

  var options = {mimeType: 'audio/webm'};
  // const startRecording = async () => {
  //   setRecordingStatus("recording");
  // //create new Media recorder instance using the stream
  // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  // const media = new MediaRecorder(stream, options);
  // //set the MediaRecorder instance to the mediaRecorder ref
  // mediaRecorder.current = media;
  // //invokes the start method to start the recording process
  // mediaRecorder.current.start();
  // let localAudioChunks = [];
  // SpeechRecognition.startListening({
  //   continuous: false,
  // });
  // mediaRecorder.current.ondataavailable = (event) => {
  //    if (typeof event.data === "undefined") return;
  //    if (event.data.size === 0) return;
  //    localAudioChunks.push(event.data);
  // };
  // console.log('1234');
  // console.log(localAudioChunks);
  // setAudioChunks(localAudioChunks);
  // };
  
  const handleMouseDown = async (e) => {
    // e.stopPropagation();

    // setIsListening(true);
    // await startRecording();
    // // SpeechRecognition.startListening({
    // //   continuous: false,
    // // });
    // timeout = setTimeout(async () => {
    //   setIsListening(false);
      
    //   stopRecording();
    // }, 100000); // Adjust the time as needed for the hold duration
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: false,
    });
  };

  const testclick = () => {
    alert("You clicked");
  };
  const buttonText = isListening
    ? "Stop"
    : isspeaking
      ? "stop speaking"
      : "Start";
  

  useEffect(() => {
    if (!listening) {
      setIsListening(false);
    }
    if (speech.speaking()) {
      
      setIsspeaking(true);
    } else if (!speech.speaking()) {
      
      setIsspeaking(false);
    }
  }, [listening, speech.speaking()]);

  useEffect(() => {
    if (!speech.speaking()) {
      
      setIsspeaking(false);
    }
  }, [speech.speaking()]);


  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu();
  const TextWaveMessageBox = ({ text }) => {
    return (
      <MessageBox
        position={"left"}
        avatar={logo}
        alt={"Jini"}
        type={"text"}
        className={"text-wave"}
        text={
          <span className="typing">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </span>
        }
      />
    );
  };
  const TableMessage = ({ text }) => {
    return (
      <MessageBox
        position={"left"}
        avatar={logo}
        alt={"Jini"}
        type={"text"}
        className="message-list"
        text={<div><h2>Items in your cart:</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>price</th>
              <th>quantity</th>
            </tr>
          </thead>
          <tbody>
            {arr.map(ar => (
              <tr key={ar.name}>
                <td>{ar.name}</td>
                <td>{ar.price}</td>
                <td style={{align: "center"}}>{ar.quantity}{ar.unit}</td>
              </tr>
            ))}
          </tbody>
        </table></div>}
      />
    );
  }

  function handleChatClick(message) {
    console.log(message);
    console.log(message.text);
    navigator.clipboard.writeText(message.text);
  }
  const handleContextMenu = (e) => {
    e.preventDefault(); 
  };  

  const MicInputButton = () => (
    <button onClick={toggleMicInput} className="bott_button">
      <img
        src={inputExpanded ? mic : keyboard}
        alt={inputExpanded ? "mic" : "keyboard"}
        onContextMenu={handleContextMenu}
      />
    </button>
  );

  const InputContainer = () => (
    <div className="input-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          ref={inputRef}
          onBlur={handleInputBlur}
          placeholder="Message..."
          value={value}
          onChange={handleInputChange}
        />
        <button type="submit">
          <img
            src={submit}
            alt="Submit"
            onContextMenu={handleContextMenu}
          />
        </button>
      </form>
    </div>
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // call the focus() method on the ref
    }
  }, [value]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const items = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' },
    // Add more items as needed
  ];

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const sendOrderDetailsToBackend = async () =>{
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // console.log(`userDetails: ${JSON.stringify(userDetails)}`)
    const data ={
      method: 'post',
      url: 'https://t1yprcpax8.execute-api.ap-south-1.amazonaws.com/dev//orderkart',
      data: {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        contact: userDetails.contact,
        address: userDetails.address,
        latitude: userDetails.latitude,
        longitude: userDetails.longitude,
        order_timestamps: null,
        order_kart: arr,
        user: userDetails.user
      }
    }
    // console.log('aryan in order');
    // console.log(userDetails);
    // console.log(JSON.stringify(data));

    const response = await axios(data);
    setIsDialogOpen(false);
    setShowPopup(true);
    console.log('mail sent')
    console.log(response);
    setArr([]);
    settt([]);
  };

  const renderStars = () => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <label key={i}>
          <input
            type="radio"
            name="stars"
            value={i}
            checked={rating === i}
            onChange={handleRatingChange}
          />
          {i <= rating ? '★' : '☆'}
        </label>
      );
    }

    return stars;
  };
  // const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [inputFeedback,setInputFeedback] = useState('');
  const handleInputChangeFeedback = (event) => {
    setInputFeedback(event.target.value);
  };

  return (
    <>
      <div id="my-element" className="page-container">
        <MessageList
          className="message-list"
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={t}
          onClick={handleChatClick}
          
        > </MessageList>
        {/* {arr && arr.length>0 ? <TableMessage /> : null} */}
        {isprocessing ? <TextWaveMessageBox /> : null}
        {/* {jsonMessage ? } */}
        <div ref={messageListRef}></div>
      </div>
      <nav className="bottom-container">
        {micExpanded && (
          <div className="bottom-container2">
            {browserSupportsSpeechRecognition ? (
              <>
                <p>Microphone: {listening ? "on" : "off"}</p>
                <p style={{ fontWeight: "bold" }}>{transcript}</p>
                {isspeaking && <p style={{ fontWeight: "bold" }}>Stop Speaking</p>}
                <div
                  className={
                    isListening || isspeaking ? "request-loader" : "loader-two"
                  }
                >
                  {isspeaking ? <button onClick={handleClick} className="start-button">
                    <img
                      className="startimg"
                      src={isListening ? microphone : isspeaking ? speaking : mute}
                      alt={buttonText}
                      onContextMenu={handleContextMenu}
                    />
                  </button>: <button onMouseDown={handleMouseDown} onMouseUp={()=>{
                    // let id=0;
                    // while(id<10000000)
                    // id++;
                    console.log('aryan in mouse up');
                    // handleMouseUp();
                    setTimeout(handleMouseUp, 1000);
                  }
                  } onTouchStart={handleTouchStart} onTouchEnd={()=>setTimeout(handleTouchEnd,1000)}className="start-button">
                    <img
                      className="startimg"
                      src={isListening ? microphone : isspeaking ? speaking : mute}
                      // alt={buttonText}
                      onContextMenu={handleContextMenu}
                    />
                  </button>}
                  
                </div>
                <MicInputButton />
              </>
            ) : (
              <>
                <p>
                  Browser doesn't support speech recognition. Try Chrome or Edge
                </p>
                <MicInputButton />
              </>
            )}
          </div>
        )}

        {inputExpanded && (
          <div className="bottom-container2">
            <InputContainer />
            {isspeaking ? (
              <button onClick={cancelaudio} className="stopSpeaking">
                Stop Speaking
              </button>
            ) : (
              <p></p>
            )}
            <br></br>
            <MicInputButton />
          </div>
        )}
        <div>
        { /*<button class="place-order-button" onClick = {openDialog}>
            <span class="icon">🛒</span>
        </button> */}
        {isDialogOpen && (
        <div className="dialog-background">
          <div className="dialog-box">
            <h2>Items in your cart:</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>price</th>
                  <th>quantity</th>
                  <th>unit</th>
                </tr>
              </thead>
              <tbody>
                {arr.map(ar => (
                  <tr key={ar.name}>
                    <td>{ar.name}</td>
                    <td>{ar.price}</td>
                    <td>{ar.quantity}</td>
                    <td>{ar.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="button-container">
              <button onClick={closeDialog} style = {{padding: "10px 20px", marginLeft: "10px"}}>Cancel</button>
              <button onClick={sendOrderDetailsToBackend} style = {{padding: "10px 20px", marginLeft: "10px"}}>Confirm Order</button>
            </div>
          </div>
        </div>
      )}
        </div>
        {showPopup && (
  <div className="dialog-background">
    <div className="dialog-box">
      <div className="star-rating">
        <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>
          Your Order is placed Successfully<br></br><br></br>
          Share your valuable Experience with us!!
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={index <= (hover || rating) ? 'on' : 'off'}
                onClick={() => {
                  setRating(index);
                  setInputFeedback(true);
                }}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                  width: '50px',
                  margin: '2px' // Adjust the margin to reduce space between stars
                }}
              >
                <span className="star" style={{ fontSize: '28px', borderRadius: '100px' }}>
                  &#9733;
                </span>
              </button>
            );
          })}
        </div>
        {inputFeedback ? (
          <div style={{ width: '90%', maxWidth: '400px', margin: '20px auto', padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
            <input
              type="text"
              placeholder="Enter your feedback..."
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc'
              }}
              onChange={handleInputChangeFeedback}
              // Add any necessary event handlers and state management for the input
            />
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
              onClick={async () => {
                const today = new Date();
                const data ={
                  method: 'post',
                  url: 'https://xhvi8z5pfa.execute-api.ap-south-1.amazonaws.com/dev/say2shop-feedback',
                  data: {
                    feedback: inputFeedback,
                    timestamp: `${today}`
                  }
                }
                console.log(JSON.stringify(data));
                const response = await axios(data);
                setShowPopup(false);
              }}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
    </div>
  </div>
)}


      </nav>
      <div style={{ position: "absolute", bottom: 10, right: 10 }}></div>
    </>
  );
};

export default Home;
